import {Panel} from "../../bananaframework/src/controls/Panel.js"
import {Image} from "../../bananaframework/src/controls/Image.js"
import {TableRow,TableHeaderCol,TableCol} from "../../bananaframework/src/controls/Table.js"
import {DataGridTileItemRender} from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js"
import {UrlManager} from "../../bananaframework/src/util/UrlManager.js"

import {ImagePlaceHolder, teamPlaceholder} from "../imageplaceholder.js"
import {MatchUtil} from "../../util/matchutil.js";
import {Localizer} from "../../util/localizer.js";
import {Voter} from "../../util/voter.js";
import {CountryUtil} from "../../util/country.js";
import {DataProvider} from "../../data/dataprovider.js";

import {Router} from "../../util/router.js";

import {CommentaryModal} from "./commentarymodal.js";
import {OddsModal} from "./oddsmodal.js";
import {MatchItemRender} from "../home/listrendershome.js"
import {ManiaPreferences} from "../../util/preferences.js";
import {RemoveReservedIfNeeded,isNumeric} from "../../util/utils.js";

export class TitleListRender extends DataGridTileItemRender{

    init ()
    {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        var card = new Panel();
        card.addCssClass("MatchTitleContainerCard");
        card.addCssClass("flexVerticalAlign");
        this.panel.addControl(card);

        this.addControl(this.panel);

        var topContainer = new Panel();
        topContainer.addCssClass("flexVerticalAlign")
        this.imageBox = new Image();
        this.imageBox.addCssClass("MatchStatsTitleImage");
        //this.imageBox.placeholderData = countryPlaceHolder

        this.titleTop = new Panel();
        this.titleTop.addCssClass("MatchStatsTitleTextTop");
        this.titleTop.setStyle("display: flex; align-items: center;");

        this.titleMiddle = new Panel();
        this.titleMiddle.addCssClass("MatchStatsTitleTextBottom");

        this.titleBottom = new Panel();
        this.titleBottom.addCssClass("MatchStatsTitleTextBottom");

        this.titleTop.addControl(this.imageBox)
        topContainer.addControl(this.titleTop)
        card.addControl(topContainer)
        card.addControl(this.titleMiddle)
        card.addControl(this.titleBottom)

        this.panel.bind('click',()=>{
            Router.showLeagueScreen(this.data.data);
        });
    }

    setData (data){
        super.setData(data);
        //this.contents.clear();
        this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/flags/"+data.data.filegroup.replaceAll(" ","-").toLowerCase()+".png",30,30);
        this.imageBox.setAttribute("alt",Localizer.getString("flag"));
        //this.titleTop.clear();

        if (!AppInstance.getConfig().enabledFeatures.showCompleteLeagueNames){
            var regex = new RegExp(AppInstance.getConfig().reservedWords.join("|"), "g");
            data.data.leaguename = data.data.leaguename.replace(regex, "");
        }

        if (FootballManiaConfig.hideCountryTitleFor.indexOf(data.data.filegroup) != -1){
            this.titleTop.addControl(Localizer.getString(data.data.leaguename));
        }
        else{
            this.titleTop.addControl(CountryUtil.getLocalizedCountryByName(data.data.filegroup));
            this.titleTop.addControl(" - "+Localizer.getString(data.data.leaguename));
        }

        if (data.data.week != null){
            if (data.data.stageName){
                this.titleMiddle.addControl(data.data.stageName+" - ");
            }
            this.titleMiddle.addControl(Localizer.getString("round")+" "+data.data.week);
            if (data.data.time && data.data.date){
                this.titleMiddle.addControl(" - "+MatchUtil.getLocalizedTime(data.data.time,data.data.date));
            }
        }
        else if (data.data.stageName){

            //console.log("complare "+data.data.stageName+" with "+data.data.leaguename+" = "+(data.data.stageName.trim() == data.data.leaguename.trim()));
            if (data.data.stageRound && data.data.stageName.trim() == data.data.leaguename.trim()){
                this.titleMiddle.addControl(data.data.stageRound);
            }
            else {
                var splitData = data.data.stageName.split("-");
                if (splitData[1]) {
                    this.titleMiddle.addControl(splitData[1]);
                } else {
                    this.titleMiddle.addControl(splitData[0]);
                }
                if (splitData[2]){
                    this.titleMiddle.addControl(" - "+splitData[2]);
                }
                if (data.data.stageRound) {
                    this.titleMiddle.addControl(" - " + data.data.stageRound);
                }
            }

            if (data.data.time && data.data.date){
                this.titleMiddle.addControl(" - "+MatchUtil.getLocalizedTime(data.data.time,data.data.date));
            }
        }


        if (data.data.venue) {
            this.titleBottom.addControl(data.data.venue);
        }
        if (data.data.referee) {
            //this.titleBottom.addControl("<br>" + Localizer.getString("referee") + ": " + data.data.referee);
            this.titleBottom.addControl(" - " + Localizer.getString("referee") + ": " + data.data.referee);
        }

        this.invalidateDisplay();
    }

    getIsSelectable ()
    {
        return false;
    }
};



export class EventListRender extends DataGridTileItemRender{

    init ()
    {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.card.setStyle("padding-bottom:4px;padding-top:6px;")
        this.panel.addControl(this.card);

        this.addControl(this.panel);
    }

    setData (data){

        super.setData(data);

        this.card.clear();
        // var title = new Panel();
        // title.addControl(Localizer.getString("match"));
        // title.addCssClass("MatchStatsTitleText")
        //
        // this.card.addControl(title);

        if (data.data.events){
            for (var i=0; i < data.data.events.length;i++){
                var event = data.data.events[i];

                if (FootballManiaConfig.isRtl){
                    if (event.team == "localteam") event.team = "awayteam";
                    else if (event.team == "visitorteam") event.team = "localteam";
                }

                //if (event.type == "subst") continue;

                var eventContainer = new Panel();
                eventContainer.addCssClass("MatchEventContainer")
                this.card.addControl(eventContainer);

                var subC = new Panel();
                eventContainer.addControl(subC);

                if (AppInstance.getConfig().enabledFeatures.playerImages) {
                    var playerImage = new Image();
                    subC.addControl(playerImage);

                    if (event.team == "localteam") {
                        subC.addCssClass("MatchStatsEventLocalTitleContainer");
                        playerImage.addCssClass("MatchStatsEventLocalPlayer");
                    } else {
                        subC.addCssClass("MatchStatsEventAwayTitleContainer");
                        playerImage.addCssClass("MatchStatsEventAwayPlayer");
                    }
                }
                else{


                    if (event.team == "localteam") {
                        subC.addCssClass("MatchStatsEventLocalTitleContainer");
                    } else {
                        subC.addCssClass("MatchStatsEventAwayTitleContainer");
                    }
                    subC.setStyle("justify-content: flex-end;");
                }


                // var imageCon = new Panel();
                // imageCon.addCssClass("tshirt-container");
                // var image = new Panel();
                // imageCon.setStyle("width:"+imageSize+"px;height:"+imageSize+"px");
                // image.setStyle("position:absolute;width:"+imageSize+"px;height:"+imageSize+"px");
                // image.addCssClass("tshirt tshirt-home");
                //
                // var image2 = new Panel();
                // image2.addCssClass("tshirt tshirt-home");


                var playerNameCon = new Panel();
                playerNameCon.addCssClass("MatchStatsEventPlayerNameContainer")

                if (event.team == "localteam") {
                    playerNameCon.setStyle("margin-left:auto;text-align:right;")
                }
                else{
                    playerNameCon.setStyle("margin-right:auto;text-align:left;")
                }
                //playerNameCon.setStyle("align-items: flex-start");

                event.player = event.player.replaceAll("(pen.)",Localizer.getString("penalty"));


                if (event.type == "pen miss"){
                    playerNameCon.addControl("<p class='MatchStatsEventPlayerName'>"+event.player+"</p>");
                    playerNameCon.addControl("<p class='MatchStatsEventPlayerAssist'>"+ Localizer.getString("missed_penalty")+ "</p>");
                }
                else if (event.assist) {
                    if (event.type == "var") {
                        playerNameCon.addControl("<p class='MatchStatsEventPlayerName'>"+event.player+"</p>");
                        if (event.assist.search(/offside/)){
                            playerNameCon.addControl("<p class='MatchStatsEventPlayerAssist'>("+Localizer.getString("offside")+")</p>");
                        }
                        else {
                            playerNameCon.addControl("<p class='MatchStatsEventPlayerAssist'>(" + event.assist + ")</p>");
                        }
                    }
                    else if (event.type == "subst"){
                        playerNameCon.addControl("<p class='MatchStatsEventPlayerSubin'>"+event.player+"</p>");
                        playerNameCon.addControl("<p class='MatchStatsEventPlayerSubout'>"+event.assist+"</p>");
                    }
                    else{
                        playerNameCon.addControl("<p class='MatchStatsEventPlayerName'>"+event.player+"</p>");
                        playerNameCon.addControl("<p class='MatchStatsEventPlayerAssist'>("+Localizer.getString(event.assist)+")</p>");
                    }
                }
                else{
                    playerNameCon.addControl("<p class='MatchStatsEventPlayerName'>"+event.player+"</p>");
                }
                subC.addControl(playerNameCon);

                var typeImage = new Image();

                if (event.type == "goal") {
                    typeImage.setImage(new URL(
                        "/images/football.png",
                        import.meta.url
                    ));
                    typeImage.setAttribute("alt",Localizer.getString("goal"));
                } else if (event.type == "yellowcard") {
                    typeImage.setImage(new URL(
                        "/images/yellowcard.png",
                        import.meta.url
                    ));
                    typeImage.setAttribute("alt",Localizer.getString("yellowcard"));
                }
                else if (event.type == "subst") {
                    typeImage.setImage(new URL(
                        "/images/subinout.png",
                        import.meta.url
                    ));
                    typeImage.setAttribute("alt",Localizer.getString("yellowcard"));
                }
                else if (event.type == "redcard") {
                    typeImage.setImage(new URL(
                        "/images/redcard.png",
                        import.meta.url
                    ));
                    typeImage.setAttribute("alt",Localizer.getString("redcard"));
                } else if (event.type == "yellowred") {
                    typeImage.setImage(new URL(
                        "/images/yellowredcard.png",
                        import.meta.url
                    ));
                    typeImage.setAttribute("alt",Localizer.getString("yellowredcard"));
                }else if (event.type == "pen miss") {
                    typeImage.setImage(new URL(
                        "/images/football_pen_miss.png",
                        import.meta.url
                    ));
                    typeImage.setAttribute("alt",Localizer.getString("missed_penalty"));
                }else if (event.type == "var") {
                    typeImage.setImage(new URL(
                        "/images/football_pen_miss.png",
                        import.meta.url
                    ));
                    typeImage.setStyle("margin-top: -12px;");
                    typeImage.setAttribute("alt",Localizer.getString("var"));

                    if (event.team == "localteam") {
                        var prop = "right";
                        if (FootballManiaConfig.isRtl) {
                            prop = "left";
                        }
                    }
                    else{
                        var prop = "left";
                        if (FootballManiaConfig.isRtl) {
                            prop = "right";
                        }
                    }
                    subC.addControl("<div class='MatchEventVarContainer' style='"+prop+":4px;'>VAR</div>");
                }

                typeImage.addCssClass("MatchStatsEventTypeImageContainer");
                subC.addControl(typeImage);

                var mid = new Panel();
                mid.addCssClass("MatchEventsMinute");

                if (isNumeric(event.extra_min) && isNumeric(event.minute)){
                   event.minute = parseInt(event.extra_min,10) + parseInt(event.minute,10)+"";
                }

                mid.addControl(event.minute+"'");
                if (event.result){
                        mid.addControl("<br><p class='MatchStatsEventMinuteREsult'>"+event.result.replaceAll(/\[|\]/g,"")+"</p>");
                }
                eventContainer.addControl(mid);

                if (event.playerId != null && event.playerId != "" && event.playerId != undefined) {
                    (function (playerId) {
                        eventContainer.bind('click', function () {
                            Router.showPlayerScreen(playerId);
                        });
                    })(event.playerId);
                }

                if (AppInstance.getConfig().enabledFeatures.playerImages) {
                    playerImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/playerimages/" + event.playerId + "_small_rnd.png");
                }
            }
        }
        this.invalidateDisplay();
    }

    getIsSelectable ()
    {
        return false;
    }
};

export class InjuryMatchRender extends DataGridTileItemRender{
    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);

        this.localContainer = new Panel();
        this.localContainer.setStyle("display:flex;flex-direction:column;width: 50%;");
        this.visitorContainer = new Panel();
        this.visitorContainer.setStyle("display:flex;flex-direction:column;width: 50%;");
    }

    setData (data) {

        this.card.clear();
        var title = new Panel();
        title.addControl(Localizer.getString("sidelined_match"));
        title.addCssClass("MatchStatsTitleText")

        this.card.addControl(title);

        var subContainer = new Panel();
        subContainer.addCssClass("MatchSubstitutesContainer")
        subContainer.addControl(this.localContainer);
        subContainer.addControl(this.visitorContainer);

        this.card.addControl(subContainer);

        var localSubs = data.data.injuries.ltsl;
        var awaySubs = data.data.injuries.vtsl;

        for (var i=0; i < localSubs.length;i++){
            this.createSideLinedItem(this.localContainer,localSubs[i])
        }
        for (var i=0; i < awaySubs.length;i++){
            this.createSideLinedItem(this.visitorContainer,awaySubs[i],true)
        }
    }

    createSideLinedItem (target,sub,reverse){
        var subC = new Panel();
        subC.setStyle("height:50px;display:flex;flex-direction:"+(reverse ? "row-reverse" : "normal")+";align-items:center;justify-content:"+(reverse ? "end" : "start"));
        var image = new Image();
        var name = new Panel();
        name.setStyle("margin-inline-start:4px;");

        if (reverse){
            name.setStyle("text-align:end;margin-inline-end:4px;");
        }

        subC.addControl(image);
        subC.addControl(name);
        target.addControl(subC);

        image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/playerimages/"+sub.id+"_small_rnd.png",40,40);
        image.setAttribute("width","40px");
        image.setAttribute("height","40px");

        var icon = new Panel();
        icon.tagName = "P";
        icon.addCssClass("playersidelinedicon");
        if (sub.status == "Yellow Cards"){
            icon.addCssClass("soccercard yellow");
        }
        else if (sub.status == "Red Card"){
            icon.addCssClass("soccercard red");
        }
        else if (sub.status == "Red Suspended"){
            icon.addCssClass("soccercard black");
        }
        else if (sub.status == "Loan agreement"){
            //icon.addCssClass("soccercard black");
        }
        else if (sub.status == "Suspended"){
            sub.status = "suspended player";
        }
        else{
            icon.addCssClass("cross");
        }

        if (!reverse){
            icon.setStyle("margin-left:-10px;margin-top:-8px;width:12px;height:12px;");
            name.addControl(icon);
        }
        name.addControl(sub.name);
        if (reverse){
            icon.setStyle("margin-left:-2px;margin-top:-8px;width:12px;height:12px;");
            name.addControl(icon);
        }


        name.addControl("<br><i style='font-size: 11px;'>"+Localizer.getString(sub.status.toLowerCase())+"</i>");
        //name.addControl("<br><i>"+(Localizer.getString(sub.status.toLowerCase().replace("/", "").replaceAll(" ", "")))+"</i>");

        subC.bind("click",function(){
            Router.showPlayerScreen(sub.id);
        });

    }
};


export class PenaltiesListRender extends DataGridTileItemRender{

    init ()
    {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.card.setStyle("padding-bottom:10px;")
        this.panel.addControl(this.card);

        this.addControl(this.panel);

        this.panel.bind('click',()=>{

        });
    }

    setData (data){

        super.setData(data);

        this.card.clear();
        var title = new Panel();
        title.addControl(Localizer.getString("penalties")+" <br> "+MatchUtil.getLocalizedPenalty(data.data));
        title.addCssClass("MatchStatsTitleText")

        this.card.addControl(title);

        if (data.data.penalties){
            for (var i=0; i < data.data.penalties.length;i++){
                var event = data.data.penalties[i];

                if (FootballManiaConfig.isRtl){
                    if (event.team == "localteam") event.team = "awayteam";
                    else if (event.team == "visitorteam") event.team = "localteam";
                }

                var eventContainer = new Panel();
                eventContainer.addCssClass("MatchEventContainer")
                eventContainer.setStyle("float:none;")
                this.card.addControl(eventContainer);

                var subC = new Panel();
                eventContainer.addControl(subC);

                var playerImage = new Image();
                subC.addControl(playerImage);

                if (event.team == "localteam"){
                    subC.addCssClass("MatchStatsEventLocalTitleContainer");
                    playerImage.addCssClass("MatchStatsEventLocalPlayer");
                }
                else{
                    subC.addCssClass("MatchStatsEventAwayTitleContainer");
                    playerImage.addCssClass("MatchStatsEventAwayPlayer");
                }

                var playerNameCon = new Panel();
                playerNameCon.addCssClass("MatchStatsEventPlayerNameContainer")
                event.player = event.player.replaceAll("(pen.)",Localizer.getString(""));
                playerNameCon.addControl("<p class='MatchStatsEventPlayerName'>"+event.player+"</p>");

                subC.addControl(playerNameCon);

                var typeImage = new Image();

                if (event.scored == 1) {
                    typeImage.setImage(new URL(
                        "/images/football.png",
                        import.meta.url
                    ));
                    typeImage.setAttribute("alt",Localizer.getString("goal"));
                }else {
                    typeImage.setImage(new URL(
                        "/images/football_pen_miss.png",
                        import.meta.url
                    ));
                    typeImage.setAttribute("alt",Localizer.getString("var"));
                }

                typeImage.addCssClass("MatchStatsEventTypeImageContainer");
                subC.addControl(typeImage);

                var mid = new Panel();
                mid.addCssClass("MatchEventsMinute");
                mid.addControl(event.minute+"'");
                if (event.result){
                    mid.addControl("<br><p class='MatchStatsEventMinuteREsult'>"+event.result.replaceAll(/\[|\]/g,"")+"</p>");
                }
                eventContainer.addControl(mid);

                (function(playerId){
                    eventContainer.bind('click',function(){
                        Router.showPlayerScreen(playerId);
                    });
                })(event.playerId);

                playerImage.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/playerimages/"+event.playerId+"_small_rnd.png");
            }
        }
        this.invalidateDisplay();
    }

    getIsSelectable ()
    {
        return false;
    }
};

export class StatsListRender extends DataGridTileItemRender{

    init ()
    {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);

        this.panel.bind('click',()=>{
        });
    }

    setData (data){
        this.card.clear();

        if (data.data.commentaries && data.data.commentaries.stats){
            var oldStatsLocal = data.data.commentaries.stats.localteam;
            var newStatsLocal = {
                possestiontime : oldStatsLocal.possestiontime,
                shotstotal : oldStatsLocal.shotstotal,
                shotsgoal : oldStatsLocal.shotsgoal,
                shotsgoalmissed : oldStatsLocal.shotsgoalmissed,
                shotsgoalblocked : oldStatsLocal.shotsgoalblocked,
                fouls : oldStatsLocal.fouls,
                corners : oldStatsLocal.corners,
                offsides : oldStatsLocal.offsides,
                yellowcards : oldStatsLocal.yellowcards,
                redcards : oldStatsLocal.redcards,
                saves : oldStatsLocal.saves,
                passestotal : oldStatsLocal.passestotal,
                passesaccurate : oldStatsLocal.passesaccurate,
            }

            var oldStatsAway = data.data.commentaries.stats.visitorteam;
            var newStatsAway = {
                possestiontime : oldStatsAway.possestiontime,
                shotstotal : oldStatsAway.shotstotal,
                shotsgoal : oldStatsAway.shotsgoal,
                shotsgoalmissed : oldStatsAway.shotsgoalmissed,
                shotsgoalblocked : oldStatsAway.shotsgoalblocked,
                fouls : oldStatsAway.fouls,
                corners : oldStatsAway.corners,
                offsides : oldStatsAway.offsides,
                yellowcards : oldStatsAway.yellowcards,
                redcards : oldStatsAway.redcards,
                saves : oldStatsAway.saves,
                passestotal : oldStatsAway.passestotal,
                passesaccurate : oldStatsAway.passesaccurate,
            }

            // if (FootballManiaConfig.isRtl){
            //     var temp = newStatsLocal;
            //     newStatsLocal = newStatsAway;
            //     newStatsAway = temp;
            // }

            //for (var statlt in data.data.commentaries.stats.localteam){
            for (var statlt in newStatsLocal){

                var localStat = newStatsLocal[statlt];
                var awayStat = newStatsAway[statlt];

                var statContainer = new Panel();
                statContainer.addCssClass("MatchComStatsContainer")

                var title = new Panel();
                title.addCssClass("MatchComStatsTitle")
                title.addControl("<p style='padding-inline-start:20px'>"+localStat+"</p><p>"+Localizer.getString(statlt)+"</p><p style='padding-inline:20px'>"+awayStat+"</p>");

                var barContainer = new Panel();
                barContainer.addCssClass("MatchComStatsBarContainer")

                var barLocal = new Panel();
                barLocal.addCssClass("MatchComStatsBarLocal")

                var barAway = new Panel();
                barAway.addCssClass("MatchComStatsBarAway")

                if (localStat == undefined || awayStat == undefined || localStat == "" || awayStat == ""){
                    console.error("unhandable sats ",statlt, localStat,awayStat);
                    continue;
                }

                localStat = localStat.replace("%","");
                awayStat = awayStat.replace("%","");

                //console.log(title,barLocal,barAway,localStat,awayStat);

                if (!isNaN(localStat) && !isNaN(awayStat)){

                    this.card.addControl(statContainer);
                    statContainer.addControl(title);
                    statContainer.addControl(barContainer);
                    barContainer.addControl(barLocal);
                    barContainer.addControl(barAway);

                    localStat = parseInt(localStat)
                    awayStat = parseInt(awayStat)
                    var total = localStat + awayStat;

                    barLocal.setCss({'width': (localStat / total) * 40 + "%", "right": "50%"})
                    barAway.setCss({'width': (awayStat / total) * 40 + "%", "left": "50%"})

                }
                else{
                    console.error("unhandable #2 sats ",statlt, localStat,awayStat);
                }
            }
        }

        this.invalidateDisplay();
        // console.log("set data",data);
    }

    getIsSelectable ()
    {
        return false;
    }
};

export class LineupListRender extends DataGridTileItemRender{

    init ()
    {
        if (AppInstance.getConfig().isAndroid || AppInstance.getConfig().isWebStandalone){
            this.fieldRatio = 125 / 80;
        }
        else{
            this.fieldRatio = 125 / 93;
        }
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("");

        this.field = new Panel();
        this.field.addCssClass("MatchField");
        this.field.addCssClass("footballField");

        this.playerContainer = new Panel();
        this.playerContainer.addCssClass("MatchLineupPlayerContainer")

        this.topNotice  = new Panel();
        this.topNotice.addCssClass("MatchLineupNotice MatchLineupPredictedTitle");
        this.topNotice.addControl(Localizer.getString("predicted_lineups"));
        this.addControl(this.topNotice);

        var topPanel = new Panel();
        topPanel.addCssClass("matchLineupTitleBlockHome");
        this.topLeftTitle = new Panel();
        this.topLeftTitle.addCssClass("matchLineupTitleTeam");
        this.topRightTitle = new Panel();
        this.topRightTitle.addCssClass("matchLineupTitleFormation");
        topPanel.addControl(this.topLeftTitle);
        topPanel.addControl(this.topRightTitle);

        var bottomPanel = new Panel();
        bottomPanel.addCssClass("matchLineupTitleBlockAway");
        this.bottomLeftTitle = new Panel();
        this.bottomLeftTitle.addCssClass("matchLineupTitleTeam");
        this.bottomRightTitle = new Panel();
        this.bottomRightTitle.addCssClass("matchLineupTitleFormation");
        bottomPanel.addControl(this.bottomLeftTitle);
        bottomPanel.addControl(this.bottomRightTitle);

        this.addControl(topPanel);
        this.addControl(this.playerContainer);
        this.addControl(this.field);
        this.addControl(bottomPanel);

    }

    createComponents (){
        var w = this.getPage().getDimensions().width;
        this.field.setCss({"width":"100%","height":w*this.fieldRatio+"px"});
        this.createPlayers();
    }

    setData (data) {

        if (!this.getPage()) {
            this.data = data;
            return;
        }
    }

    createPlayers (){

        if (!this.data){
            return console.error("no data set for lineups");
        }

        this.panel.clear();

        if (!this.data.data.commentaries.isPredicted){
            this.topNotice.setVisible(false);
        }

        var data = this.data;

        var substitutionOffIds = [];
        var playerStatsById = {};
        //determine substitutions

        if (data.data.commentaries.substitutions) {
            if (data.data.commentaries.substitutions.localteam) {
                for (var i = 0; i < data.data.commentaries.substitutions.localteam.length; i++) {
                    var sub = data.data.commentaries.substitutions.localteam[i];
                    substitutionOffIds.push(sub.off_id);
                }
            }
            if (data.data.commentaries.substitutions.visitorteam) {
                for (var i = 0; i < data.data.commentaries.substitutions.visitorteam.length; i++) {
                    var sub = data.data.commentaries.substitutions.visitorteam[i];
                    substitutionOffIds.push(sub.off_id);
                }
            }
        }

        if (data.data.commentaries.playerstats && data.data.commentaries.playerstats.localteam && data.data.commentaries.playerstats.localteam.length > 0
        && data.data.commentaries.playerstats && data.data.commentaries.playerstats.visitorteam && data.data.commentaries.playerstats.visitorteam.length > 0
        ){
            for (var i=0; i < data.data.commentaries.playerstats.localteam.length; i++){
                var player = data.data.commentaries.playerstats.localteam[i];
                playerStatsById[player.id] = player;
            }
            for (var i=0; i < data.data.commentaries.playerstats.visitorteam.length; i++){
                var player = data.data.commentaries.playerstats.visitorteam[i];
                playerStatsById[player.id] = player;
            }
        }

        //do some manual lineup fixing for in case we dont have v3 lineups, but v2
        //todo: also fix when no v2, but v1 is available
        if (!this.data.data.commentaries.teams.visitorFormationTypeV3){
            //if we have old known type

            if (!this.data.data.commentaries.teams.visitorFormationType2 && this.data.data.commentaries.teams.visitorFormationType){
                this.data.data.commentaries.teams.visitorFormationType2 = this.data.data.commentaries.teams.visitorFormationType;
            }

            //if we have no type we try to guess it
            if (!this.data.data.commentaries.teams.visitorFormationType2){
                var prevPos = "";
                var posIndex = -1;
                var posTypeCount = [];
                for (var i=1; i < data.data.commentaries.teams.visitorteam.length;i++) {
                    if (prevPos != data.data.commentaries.teams.visitorteam[i].pos){
                        posIndex++;
                        posTypeCount[posIndex] = 1;
                    } else {
                        posTypeCount[posIndex]++;
                    }
                    prevPos = data.data.commentaries.teams.visitorteam[i].pos;
                }
                if (posTypeCount.length == 3) {
                    var formationType = posTypeCount[0] + "-" + posTypeCount[1] + "-" + posTypeCount[2];
                    this.data.data.commentaries.teams.visitorFormationType2 = formationType;
                }
                else{
                    console.warn("unable to determine linups localteam, use default");
                    this.data.data.commentaries.teams.visitorFormationType2 = "4-3-3";
                }
            }

            if (this.data.data.commentaries.teams.visitorFormationType2){

                this.data.data.commentaries.teams.visitorFormationTypeV3 = this.data.data.commentaries.teams.visitorFormationType2;

                var split = this.data.data.commentaries.teams.visitorFormationType2.split("-");
                var hCount = split.length;
                this.data.data.commentaries.teams.visitorteam[0].x = 0.5;
                this.data.data.commentaries.teams.visitorteam[0].y = 0.05;
                var pCount = 1;
                var hHeight = 1/split.length;
                var curHHeight = hHeight

                for (var i=0; i< split.length;i++){
                    //console.log(split[i]);
                    var wStart = 1;
                    var playerLineCount = parseInt(split[i]);

                    for (var j= 0; j < split[i]; j++){
                        wStart -=1/(playerLineCount+1);
                        this.data.data.commentaries.teams.visitorteam[pCount].x = wStart;
                        this.data.data.commentaries.teams.visitorteam[pCount].y = curHHeight;
                        //console.log("->",playerLineCount,pCount,hHeight,1/(playerLineCount+1),wStart);
                        pCount++;
                    }
                    curHHeight+=hHeight;
                }
            }
        }

        if (!this.data.data.commentaries.teams.localFormationTypeV3){

            if (!this.data.data.commentaries.teams.localFormationTypeV2 && this.data.data.commentaries.teams.localFormationType){
                this.data.data.commentaries.teams.localFormationType2 = this.data.data.commentaries.teams.localFormationType;
            }

            //if we have no type we try to guess it
            if (!this.data.data.commentaries.teams.localFormationType2){
                console.log("do something")
                var prevPos = "";
                var posIndex = -1;
                var posTypeCount = [];
                for (var i=1; i < data.data.commentaries.teams.localteam.length;i++) {
                    if (prevPos != data.data.commentaries.teams.localteam[i].pos){
                        posIndex++;
                        posTypeCount[posIndex] = 1;
                    } else {
                        posTypeCount[posIndex]++;
                    }
                    prevPos = data.data.commentaries.teams.localteam[i].pos;
                }
                if (posTypeCount.length == 3) {
                    var formationType = posTypeCount[0] + "-" + posTypeCount[1] + "-" + posTypeCount[2];
                    this.data.data.commentaries.teams.localFormationType2 = formationType;
                }
                else{
                    console.warn("unable to determine linups localteam, use default");
                    this.data.data.commentaries.teams.localFormationType2 = "4-3-3";
                }
            }

            //if we have old known type
            if (this.data.data.commentaries.teams.localFormationType2){

                this.data.data.commentaries.teams.localFormationTypeV3 = this.data.data.commentaries.teams.localFormationType2;

                var split = this.data.data.commentaries.teams.localFormationType2.split("-");

                this.data.data.commentaries.teams.localteam[0].x = 0.5;
                this.data.data.commentaries.teams.localteam[0].y = 0.05;
                var pCount = 1;
                var hHeight = 1/split.length;
                var curHHeight = hHeight

                for (var i=0; i< split.length;i++){
                    //console.log(split[i]);
                    var wStart = 1;
                    var playerLineCount = parseInt(split[i]);

                    for (var j= 0; j < split[i]; j++){
                        wStart -=1/(playerLineCount+1);
                        this.data.data.commentaries.teams.localteam[pCount].x = wStart;
                        this.data.data.commentaries.teams.localteam[pCount].y = curHHeight;
                        //console.log("->",playerLineCount,pCount,hHeight,1/(playerLineCount+1),wStart);
                        pCount++;
                    }
                    curHHeight+=hHeight;
                }
            }
        }

        var targettedHeight =  this.getPage().getDimensions().width * this.fieldRatio;

        var blockSize = 70;
        var blockWidth = blockSize*1.4;
        var imageSize = 40;

        this.topLeftTitle.addControl("<p>"+this.data.data.localteam);
        if (this.data.data.coachLocal) this.topLeftTitle.addControl("</p> &nbsp;<p> ("+this.data.data.coachLocal+"</p>)");
        this.topRightTitle.addControl("<p>"+this.data.data.commentaries.teams.localFormationTypeV3+"</p>");

        this.bottomLeftTitle.addControl("<p>"+this.data.data.visitorteam);
        if (this.data.data.coachVisitor) this.bottomLeftTitle.addControl("</p> &nbsp;<p> ("+this.data.data.coachVisitor+"</p>)");
        this.bottomRightTitle.addControl("<p>"+this.data.data.commentaries.teams.visitorFormationTypeV3+"</p>");

        for (var i=0; i < data.data.commentaries.teams.localteam.length;i++){
            var player = data.data.commentaries.teams.localteam[i];

            //console.log(targettedHeight,player.y);
            var p = new Panel();
            p.addCssClass("MatchLineupBlockContainer");
            var title = new Panel();
            title.addCssClass("MatchLineupBlockTitle")
            title.addControl(player.name);
            var imageCon = new Panel();
            imageCon.addCssClass("tshirt-container");
            var image = new Panel();
            imageCon.setStyle("width:"+imageSize+"px;height:"+imageSize+"px");
            image.setStyle("position:absolute;width:"+imageSize+"px;height:"+imageSize+"px");
            image.addCssClass("tshirt tshirt-home");

            var image2 = new Panel();
            image2.addCssClass("tshirt tshirt-home");

            if (playerStatsById[player.id]){

                if (playerStatsById[player.id].rating) {
                    var ratingPanel = new Panel();
                    var rating = playerStatsById[player.id].rating;
                    ratingPanel.addCssClass("MatchLineupPlayerRating")
                    imageCon.addControl(ratingPanel);
                    ratingPanel.addControl(rating);
                    if (rating < 5.5) {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingBad");
                    } else if (rating < 6.5) {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingOkaish");
                    } else if (rating < 7) {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingAvarage");
                    } else if (rating < 8.5) {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingGood");
                    } else {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingExcellent");
                    }
                }

                if (playerStatsById[player.id].goals){
                    var goalPanel = new Panel();
                    goalPanel.addCssClass("MatchLineupPlayerGoal");
                    imageCon.addControl(goalPanel);
                }
            }


            if (substitutionOffIds.indexOf(player.id) != -1){
                var offIcon = new Panel();
                offIcon.addCssClass("icon-arrow-out");
                offIcon.setStyle("width:25px; height:25px;position:absolute;top:-15px;right:-7px;z-index:1000;");
                imageCon.addControl(offIcon);

                // (function(imageCon,offIcon){
                //     var r = 180;
                //     setTimeout(function(){
                //         jQuery("#"+imageCon.getClientId()).bind("animationend transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",function(){
                //
                //             setTimeout(function(){
                //                 if (r == 180){
                //                     r = 0
                //                 }
                //                 else{
                //                     r = 180;
                //                 }
                //                 //jQuery("#"+imageCon.getClientId()).css({"transform":"rotateY("+r+"deg)","transition-duration": "1000ms"});
                //
                //                 if (r == 180) {
                //                     imageCon.removeCssClass("tshirt-container-flip-show-off")
                //                     imageCon.addCssClass("tshirt-container-flip-show-in")
                //                 }
                //                 else{
                //                     imageCon.removeCssClass("tshirt-container-flip-show-in")
                //                     imageCon.addCssClass("tshirt-container-flip-show-off")
                //                 }
                //
                //                 setTimeout(function(){
                //                     // // console.log("rrrr",r)
                //                     // if (r == 0){
                //                     //     offIcon.removeCssClass("icon-arrow-in");
                //                     // }
                //                     // else{
                //                     //     offIcon.addCssClass("icon-arrow-in");
                //                     // }
                //                 },500);
                //
                //             },1000)
                //         });
                //         imageCon.addCssClass("tshirt-container-flip-show-in")
                //     },1000);
                // })(imageCon,offIcon);
            }

            image.addControl(player.number);
            image.setAttribute("alt",Localizer.getString("t-shirt"));
            //image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/playerimages/"+player.id+"_small_rnd.png");
            p.addControl(imageCon);
            imageCon.addControl(image);
            p.addControl(title);

            if (data.data.commentaries.localteamcolor){
                image.setStyle("color:#"+data.data.commentaries.localteamnumbercolor+";background-color:#"+data.data.commentaries.localteamcolor)
                image2.setStyle("opacity:0.3;background-color:#"+data.data.commentaries.localteamnumbercolor+";top:-1px;left:-1px;z-index:-1;position:absolute;width:"+(imageSize+2)+"px;height:"+(imageSize+2)+"px");
                imageCon.addControl(image2);
            }

            var top = (player.y*((targettedHeight/2)-blockSize));

            p.setCss({"top": top+"px","left": "calc("+(100-(player.x*100))+"% - "+(blockWidth/2)+"px)","width":blockWidth+"px","height":blockSize+"px","position":"absolute"});
            this.playerContainer.addControl(p);

            (function(playerId){
                p.bind('click',function(){
                    Router.showPlayerScreen(playerId);
                });
            })(player.id);
        }

        for (var i=0; i < data.data.commentaries.teams.visitorteam.length;i++){
            var player = data.data.commentaries.teams.visitorteam[i];

            var top = (targettedHeight/2) + ((1-player.y)*((targettedHeight/2)-blockSize));

            var p = new Panel();
            p.addCssClass("MatchLineupBlockContainer");
            var title = new Panel();
            title.addCssClass("MatchLineupBlockTitle")
            title.addControl(player.name);
            var imageCon = new Panel();
            imageCon.addCssClass("tshirt-container");
            var image = new Panel();
            image.setStyle("width:"+imageSize+"px;height:"+imageSize+"px");
            image.addCssClass("tshirt tshirt-away");

            var image2 = new Panel();
            image2.addCssClass("tshirt tshirt-away");

            if (playerStatsById[player.id]){

                if (playerStatsById[player.id].rating) {
                    var ratingPanel = new Panel();
                    var rating = playerStatsById[player.id].rating;
                    ratingPanel.addCssClass("MatchLineupPlayerRating")
                    imageCon.addControl(ratingPanel);
                    ratingPanel.addControl(rating);
                    if (rating < 5.5) {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingBad");
                    } else if (rating < 6.5) {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingOkaish");
                    } else if (rating < 7) {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingAvarage");
                    } else if (rating < 8.5) {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingGood");
                    } else {
                        ratingPanel.addCssClass("MatchLineupPlayerRatingExcellent");
                    }
                }

                if (playerStatsById[player.id].goals){
                    var goalPanel = new Panel();
                    goalPanel.addCssClass("MatchLineupPlayerGoal");
                    imageCon.addControl(goalPanel);
                }
            }

            if (substitutionOffIds.indexOf(player.id) != -1){
                var offIcon = new Panel();
                offIcon.addCssClass("icon-arrow-out");
                offIcon.setStyle("width:25px; height:25px;position:absolute;top:-15px;right:-7px;z-index:1000;");
                imageCon.addControl(offIcon);
            }

            image.addControl(player.number);
            //image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/images/playerimages/"+player.id+"_small_rnd.png");
            p.addControl(imageCon);
            imageCon.addControl(image);
            p.addControl(title);

            if (data.data.commentaries.visitorteamcolor){
                //image.setStyle("background-color:#"+data.data.commentaries.visitorteamcolor)
                image.setStyle("color:#"+data.data.commentaries.visitorteamnumbercolor+";background-color:#"+data.data.commentaries.visitorteamcolor)
                image2.setStyle("opacity:0.3;background-color:#"+data.data.commentaries.visitorteamnumbercolor+";top:-1px;left:-1px;z-index:-1;position:absolute;width:"+(imageSize+2)+"px;height:"+(imageSize+2)+"px");
                imageCon.addControl(image2);
            }

            p.setCss({"top": top+"px","left": "calc("+(player.x*100)+"% - "+(blockWidth/2)+"px)","width":blockWidth+"px","height":blockSize+"px","position":"absolute"});
            this.playerContainer.addControl(p);

            (function(playerId){
                p.bind('click',function(){
                    Router.showPlayerScreen(playerId);
                });
            })(player.id);
        }

        this.invalidateDisplay();
    }

    updateDisplay (){
        //var w = this.panel.getDimensions().width;
        // console.log(this.panel.getDimensions());
        //this.field.setCss({"width":(w-200)+"px","height":w*this.fieldRatio+"px"});
    }
};



export class SubstitutionsListRender extends DataGridTileItemRender{

    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);

    }

    setData (data) {

        this.card.clear();
        var title = new Panel();
        title.addControl(Localizer.getString("substitutions"));
        title.addCssClass("MatchStatsTitleText")

        this.card.addControl(title);

        this.localContainer = new Panel();
        this.visitorContainer = new Panel();

        this.card.addControl(this.localContainer);
        this.card.addControl(this.visitorContainer);

        var localSubs = data.data.commentaries.substitutions.localteam;
        var awaySubs = data.data.commentaries.substitutions.visitorteam;
        var allSubs = [];

        for (var i=0; i < localSubs.length;i++){
            localSubs[i].team = "localteam";
            allSubs.push(localSubs[i]);
        }
        for (var i=0; i < awaySubs.length;i++){
            awaySubs[i].team = "awayteam";
            allSubs.push(awaySubs[i]);
        }

        allSubs.sort(function(a,b){
            return b.minute - a.minute;
        })

        for (var i=0; i < allSubs.length;i++){
            var event = allSubs[i];

            var float = "";
            //var float = "right"
            if (event.team == "localteam"){
                //float = "left";
            }

            var eventContainer = new Panel();
            eventContainer.addCssClass("MatchSubstitutionsContainer")
            eventContainer.setStyle("float:"+float+";height:80px;")
            this.card.addControl(eventContainer);

            var subC = new Panel();
            eventContainer.addControl(subC);

            var playerInContainer = new Panel();
            var playerOutContainer = new Panel();

            if (event.team == "localteam") {
                playerInContainer.addCssClass("MatchSubstitutionsPlayerLocalContainer")
                playerOutContainer.addCssClass("MatchSubstitutionsPlayerLocalContainer")
            }
            else{
                playerInContainer.addCssClass("MatchSubstitutionsPlayerAwayContainer")
                playerOutContainer.addCssClass("MatchSubstitutionsPlayerAwayContainer")
            }

            for (var j=0; j < 2; j++) {

                if (j==0){
                    var targetCon = playerInContainer;
                    var d = {minute : event.minute,player : event.on, id : event.on_id, injury : event.injury == "True" ? true : false,"css":"icon-subout-in"};
                }
                else{
                    targetCon = playerOutContainer;
                    d = {minute: event.minute,player : event.off, id : event.off_id, injury : event.injury == "True" ? true : false,"css":"icon-subout-out"};
                }

                var playerImage = new Image();
                targetCon.addControl(playerImage);

                var playerNameCon = new Panel();
                playerNameCon.addCssClass("MatchStatsEventPlayerNameContainer")
                playerNameCon.addControl("<p class='MatchStatsEventPlayerName'>" + d.player + "</p>");
                targetCon.addControl(playerNameCon);

                var typeImage = new Panel();
                typeImage.addCssClass(d.css)
                typeImage.setAttribute("alt",Localizer.getString("Substitution"));
                typeImage.addCssClass("MatchStatsEventTypeImageContainer");
                targetCon.addControl(typeImage);
                playerImage.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/playerimages/"+d.id+"_small_rnd.png");

                if (FootballManiaConfig.isRtl){
                    if (event.team == "localteam") {
                        subC.addCssClass("MatchSubstitutionsContainerAway");
                        playerImage.addCssClass("MatchStatsEventAwayPlayer");
                    } else {
                        subC.addCssClass("MatchSubstitutionsContainerLocal");
                        playerImage.addCssClass("MatchStatsEventLocalPlayer");
                    }
                }else{
                    if (event.team == "localteam") {
                        subC.addCssClass("MatchSubstitutionsContainerLocal");
                        playerImage.addCssClass("MatchStatsEventLocalPlayer");
                    } else {
                        subC.addCssClass("MatchSubstitutionsContainerAway");
                        playerImage.addCssClass("MatchStatsEventAwayPlayer");
                    }
                }
            }

            subC.addControl(playerInContainer)
            subC.addControl(playerOutContainer)

            var mid = new Panel();
            //mid.setStyle("float:"+float);
            mid.addCssClass("MatchEventsMinute");
            mid.addControl(d.minute+"'");
            if (event.result){
                mid.addControl("<br><p class='MatchStatsEventMinuteREsult'>"+event.result.replaceAll(/\[|\]/g,"")+"</p>");
            }
            eventContainer.addControl(mid);

            (function(playerOnId,playerOffId){
                playerInContainer.bind('click',function(){
                    Router.showPlayerScreen(playerOnId);
                });
                playerOutContainer.bind('click',function(){
                    Router.showPlayerScreen(playerOffId);
                });
            })(event.on_id,event.off_id);
        }
    }
};

export class SubstitutesListRender extends DataGridTileItemRender{

    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);

        this.localContainer = new Panel();
        this.localContainer.setStyle("display:flex;flex-direction:column;width: 50%;");
        this.visitorContainer = new Panel();
        this.visitorContainer.setStyle("display:flex;flex-direction:column;width: 50%;");
    }

    setData (data) {

        this.card.clear();
        var title = new Panel();
        title.addControl(Localizer.getString("substitutes"));
        title.addCssClass("MatchStatsTitleText")

        this.card.addControl(title);

        var subContainer = new Panel();
        subContainer.addCssClass("MatchSubstitutesContainer")
        subContainer.addControl(this.localContainer);
        subContainer.addControl(this.visitorContainer);

        this.card.addControl(subContainer);

        var localSubs = data.data.commentaries.substitutes.localteam;
        var awaySubs = data.data.commentaries.substitutes.visitorteam;

        for (var i=0; i < localSubs.length;i++){
            this.createSubstituteItem(this.localContainer,localSubs[i])
        }
        for (var i=0; i < awaySubs.length;i++){
            this.createSubstituteItem(this.visitorContainer,awaySubs[i],true)
        }
    }

    createSubstituteItem (target,sub,reverse){
        var subC = new Panel();
        subC.setStyle("height:50px;display:flex;flex-direction:"+(reverse ? "row-reverse" : "normal")+";align-items:center;justify-content:"+(reverse ? "end" : "start"));
        var image = new Image();
        var name = new Panel();
        name.setStyle("margin-inline-start:4px;");

        if (reverse){
            name.setStyle("text-align:end;margin-inline-end:4px;");
        }

        subC.addControl(image);
        subC.addControl(name);
        target.addControl(subC);

        image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/playerimages/"+sub.id+"_small_rnd.png",40,40);
        image.setAttribute("width","40px");
        image.setAttribute("height","40px");
        name.addControl(sub.name+"<br>");

        if (sub.pos == "F"){
            name.addControl("<i>"+Localizer.getString("attacker")+"</i>");
        }
        if (sub.pos == "D"){
            name.addControl("<i>"+Localizer.getString("defender")+"</i>");
        }
        if (sub.pos == "M"){
            name.addControl("<i>"+Localizer.getString("midfielder")+"</i>");
        }
        if (sub.pos == "G"){
            name.addControl("<i>"+Localizer.getString("goalkeeper")+"</i>");
        }

        subC.bind("click",function(){
           Router.showPlayerScreen(sub.id);
        });

    }
};



export class H2HListRender extends DataGridTileItemRender{

    init ()
    {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);

    }

    setData (data) {

        this.card.clear();
        var title = new Panel();
        title.addControl(Localizer.getString("h2h"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var totalPlayed = data.data.stats.total_draws + data.data.stats.total_localteam_won + data.data.stats.total_visitorteam_won;
        var homePercentage = (data.data.stats.total_localteam_won / totalPlayed) * 100;
        var drawPercentage = (data.data.stats.total_draws / totalPlayed) * 100;
        var awayPercentage = (data.data.stats.total_visitorteam_won / totalPlayed) * 100;


        var statsPanel = new Panel();
        statsPanel.addCssClass("MatchH2HStatsContainer")

        var homeWins = new Panel();
        homeWins.addCssClass("pie no-round");

        if (this.firstTime){
            //homeWins.addCssClass("animate");
        }
        homeWins.setStyle("--p:"+homePercentage+";--c:lightgreen");
        homeWins.addControl(data.data.stats.total_localteam_won+"<br>"+Localizer.getString("table_wins_full"));
        statsPanel.addControl(homeWins);

        var draws = new Panel();
        draws.addCssClass("pie no-round");

        if (this.firstTime){
            //homeWins.addCssClass("animate");
        }
        draws.setStyle("--p:"+drawPercentage+";--c:lightgreen");
        draws.addControl(data.data.stats.total_draws+"<br>"+Localizer.getString("table_draw_full"));
        statsPanel.addControl(draws);

        var awayWins = new Panel();
        awayWins.addCssClass("pie no-round");

        if (this.firstTime){
            //homeWins.addCssClass("animate");
        }
        awayWins.setStyle("--p:"+awayPercentage+";--c:lightgreen");
        awayWins.addControl(data.data.stats.total_visitorteam_won+"<br>"+Localizer.getString("table_wins_full"));
        statsPanel.addControl(awayWins);

        this.card.addControl(statsPanel);

        var title = new Panel();
        title.addControl(Localizer.getString("previous_matches"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);
        var listType = ManiaPreferences.getMatchListType();
        for (var i=0; i < data.data.h2hMatches.length;i++){

            var info = new Panel();
            info.addCssClass("MatchH2HMatchTitleContainer")

            info.addControl("<p>"+MatchUtil.getLocalizedDate(data.data.h2hMatches[i])+"</p>");

            data.data.h2hMatches[i].leaguename = RemoveReservedIfNeeded(data.data.h2hMatches[i].leaguename);

            info.addControl("<p>"+data.data.h2hMatches[i].leaguename+"</p>");
            //console.log(data.data.h2hMatches[i]);

            var p = new Panel();
            var render = new MatchItemRender();
            render.hideBell = true;

            var listRenderHeight = 70;

            if (listType == "3"){
                var itemHeight = 70;
                listRenderHeight+=20;
            }
            else {
                itemHeight = 55;
            }

            p.setStyle("width:100%;height:"+listRenderHeight+"px;position:relative;");

            data.data.h2hMatches[i].style = {height : itemHeight};
            render.setData(data.data.h2hMatches[i],false,true);
            p.addControl(info);
            p.addControl(render);
            this.card.addControl(p);
        }
        this.firstTime = false;
    }
};


export class AggregateRender extends DataGridTileItemRender{

    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.card.setStyle("padding-top:4px;padding-bottom:10px;")
        this.panel.addControl(this.card);

        this.addControl(this.panel);
    }

    setData (data) {

        this.data = data;

        this.card.clear();
        var title = new Panel();
        title.setStyle("line-height: 17px;")

        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        title.addControl(Localizer.getString("aggregate")+"<br>"+MatchUtil.getLocalizedAggregateScore(data.data).replace(/[{()}]/g, ''));

        var previousScore = new Panel();

        //previousScore.addControl(MatchUtil.getLocalizedAggregateScore(data.data));
        this.card.addControl(previousScore);

        var aggregateMatches = [];

        for (var i=0; i < data.data.aggregate.matches.length;i++){
            if (data.data.aggregate.matches[i] != data.data.id){
                aggregateMatches.push(data.data.aggregate.matches[i]);
            }
        }

        for (var i=0; i < aggregateMatches.length;i++){

            var aggregateMatch  = null;

            if (data.data.h2hMatches) {
                //look up match in h2h matches
                for (var j = 0; j < data.data.h2hMatches.length; j++) {
                    if (data.data.h2hMatches[j].id == aggregateMatches[i]) {
                        aggregateMatch = data.data.h2hMatches[j];
                    }
                }
            }

            if (!aggregateMatch) continue;

            var info = new Panel();
            info.addCssClass("MatchH2HMatchTitleContainer")
            info.setStyle("justify-content: center;");

            info.addControl("<p>"+MatchUtil.getLocalizedDate(aggregateMatch)+"</p>");

            var height = 70;
            if (ManiaPreferences.getMatchListType() == "1"){
                height = 50;
            }

            var p = new Panel();
            p.setStyle("width:100%;height:"+(height+20)+"px;position:relative;");
            var render = new MatchItemRender(true);
            render.hideBell = true;
            aggregateMatch.style = {height : height};
            render.setData(aggregateMatch,false,true);
            p.addControl(info);
            p.addControl(render);
            this.card.addControl(p);
        }
    }
};

export class VotingRender extends DataGridTileItemRender{

    init ()
    {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);
    }

    createEmptyChart (userVote){
        var homeResult = new Panel();
        homeResult.addCssClass("MatchVoteChart");
        homeResult.setCss({"height": "1px"});
        if (userVote == 0) homeResult.addCssClass("MatchVoteChartIsVotedByUser");
        var drawResult = new Panel();
        drawResult.addCssClass("MatchVoteChart");
        drawResult.setCss({"height": "1px"});
        if (userVote == 2) drawResult.addCssClass("MatchVoteChartIsVotedByUser");
        var awayResult = new Panel();
        awayResult.addCssClass("MatchVoteChart");
        awayResult.setCss({"height": "1px"});
        if (userVote == 1) awayResult.addCssClass("MatchVoteChartIsVotedByUser");
        this.homeResult = homeResult;
        this.drawResult = drawResult;
        this.awayResult = awayResult;

        this.chartContainer.addCssClass("MatchVoteChartContainer");

        this.chartContainer.addControl(homeResult)
        this.chartContainer.addControl(drawResult)
        this.chartContainer.addControl(awayResult)
        this.chartContainer.invalidateDisplay();
    }

    createVoteButtons (){

        this.voteHome = new Panel();
        this.voteHome.addCssClass("MatchVoteButton")
        this.voteHome.addControl(this.data.data.localteam);
        this.voteDraw = new Panel();
        this.voteDraw.addCssClass("MatchVoteButton")
        this.voteDraw.addControl(Localizer.getString("draw"));
        this.voteAway = new Panel();
        this.voteAway.addCssClass("MatchVoteButton")
        this.voteAway.addControl(this.data.data.visitorteam);

        this.voteHome.bind("click",()=>{
            this.voteData.home +=1;
            Voter.voteMatch(this.data.data.id,0)
            this.chartContainer.setVisible(true)
            this.buttonContainer.setVisible(false);
            this.createEmptyChart();
            this.updateChart(true,0)
        });

        this.voteDraw.bind("click",()=>{
            this.voteData.draw +=1;
            Voter.voteMatch(this.data.data.id,2)
            this.chartContainer.setVisible(true)
            this.buttonContainer.setVisible(false);
            this.createEmptyChart();
            this.updateChart(true,1)
        });

        this.voteAway.bind("click",()=>{
            this.voteData.away +=1;
            Voter.voteMatch(this.data.data.id,1)
            this.chartContainer.setVisible(true)
            this.buttonContainer.setVisible(false);
            this.createEmptyChart();
            this.updateChart(true,2)
        });
    }

    updateChart (forceShowResult,highLightSide){

        var voteData = this.voteData;
        this.voteContainer.clear();
        if (forceShowResult || MatchUtil.isFinished(this.data.data)) {
            this.homeResult.addControl("<p style='margin-top:-20px;text-align: center'>" + voteData.home + " "+ "</p>",true)
            this.homeResult.setCss({"height": +((voteData.home/(voteData.home+voteData.away+voteData.draw))*100)+"%"});
            if (highLightSide == 0) this.homeResult.addCssClass("MatchVoteChartIsVotedByUser");
            this.drawResult.addControl("<p style='margin-top:-20px;text-align: center'>" + voteData.draw + " " + "</p>",true)
            this.drawResult.setCss({"height": +((voteData.draw/(voteData.home+voteData.away+voteData.draw))*100)+"%"});
            if (highLightSide == 1) this.drawResult.addCssClass("MatchVoteChartIsVotedByUser");
            this.awayResult.addControl("<p style='margin-top:-20px;text-align: center'>" + voteData.away + " "+ "</p>",true)
            this.awayResult.setCss({"height": +((voteData.away/(voteData.home+voteData.away+voteData.draw))*100)+"%"});
            if (highLightSide == 2) this.awayResult.addCssClass("MatchVoteChartIsVotedByUser");

            this.voteContainer.addControl("<p class='matchVoteResult' style=width:28%;>"+this.data.data.localteam+"</p>");
            this.voteContainer.addControl("<p class='matchVoteResult' style=width:28%;>"+Localizer.getString("draw")+"</p>");
            this.voteContainer.addControl("<p class='matchVoteResult' style=width:28%;>"+this.data.data.visitorteam+"</p>");

            //buttonContainer.invalidateDisplay();
        }
        else{
            this.voteContainer.addControl("<p class='matchVoteResult' style=width:28%;>"+voteData.home + " " + Localizer.getString("votes")+"</p>");
            this.voteContainer.addControl("<p class='matchVoteResult' style=width:28%;>"+voteData.draw + " " + Localizer.getString("votes")+"</p>");
            this.voteContainer.addControl("<p class='matchVoteResult' style=width:28%;>"+voteData.away + " " + Localizer.getString("votes")+"</p>");
        }

        this.voteContainer.invalidateDisplay();
    }

    loadVoteResults (showChart){
        DataProvider.getMatchVotes(this.data.data.id,(response)=>{
            if (!response.isOk()){
                return;
            }
            this.voteData = response.getData();
            this.updateChart(showChart);
        });
    }

    setData (data) {

        this.data = data;

        this.card.clear();
        var title = new Panel();

        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        this.buttonContainer = new Panel();
        this.buttonContainer.addCssClass("MatchVoteContainer");

        this.chartContainer =  new Panel();
        this.chartContainer.addCssClass("MatchVoteContainer");

        this.voteContainer = new Panel();
        this.voteContainer.addCssClass("MatchVoteContainer")

        var userVote = Voter.getVote(data.data.id);

        if (userVote != undefined){
            title.addControl(Localizer.getString("already_voted"));
            this.createEmptyChart(userVote);
            this.loadVoteResults(true);
            this.buttonContainer.setVisible(false);
        }
        else if (MatchUtil.isFinished(data.data)){
           title.addControl(Localizer.getString("votes"));
           this.buttonContainer.setVisible(false)
           this.createEmptyChart();
            this.loadVoteResults();
        }
        else {
            title.addControl(Localizer.getString("voteMatch"));
            this.createVoteButtons();
            this.loadVoteResults();
            this.chartContainer.setVisible(false);
            //this.chartContainer.setVisible(false)
        }

        this.buttonContainer.addControl(this.voteHome);
        this.buttonContainer.addControl(this.voteDraw);
        this.buttonContainer.addControl(this.voteAway);
        this.card.addControl(this.buttonContainer);
        this.card.addControl(this.chartContainer);
        this.card.addControl(this.voteContainer);
    }

    updateDisplay (){

    }
};


export class StandingsRender extends DataGridTileItemRender{

    init ()
    {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);
    }

    setData (data){
        super.setData(data);

        var title = new Panel();
        title.addControl(Localizer.getString("standen"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        this.card.addControl("<table class='tableStandings'>");

        var groups = [
            data.data.localteamStanding,
            data.data.visitorteamStanding,
        ]

        var row = new TableRow();
        row.setStyle("height:25px;")
        var th1 = new TableHeaderCol();

        var th1Arrow = new TableHeaderCol();
        var th2 = new TableHeaderCol();
        th1Arrow.setAttribute("colspan",3)

        th1Arrow.setStyle("text-align:left;padding-inline-start:4px;")
        var th3= new TableHeaderCol();
        var th4= new TableHeaderCol();
        th4.addControl(Localizer.getString("table_gamesplayed"))
        var th5= new TableHeaderCol();
        th5.addControl(Localizer.getString("table_wins"))
        var th6= new TableHeaderCol();
        th6.addControl(Localizer.getString("table_draw"))
        var th7= new TableHeaderCol();
        th7.addControl(Localizer.getString("table_lost"))
        var th8= new TableHeaderCol();
        th8.addControl(Localizer.getString("table_goalsforagainst"))
        th8.setStyle("min-width:50px;")
        var th9= new TableHeaderCol();
        th9.addControl(Localizer.getString("table_goaldiff"))
        var th10= new TableHeaderCol();
        th10.addControl(Localizer.getString("table_points"))
        //row.addControl(th1);
        row.addControl(th1Arrow);
        //row.addControl(th2);
        //row.addControl(th3);
        row.addControl(th4);
        row.addControl(th5);
        row.addControl(th6);
        row.addControl(th7);
        row.addControl(th8);
        row.addControl(th9);
        row.addControl(th10);

        this.card.addControl(row);

        if (groups[0].position > groups[1].position){
            groups.reverse();
        }

        for (var j=0; j < groups.length;j++) {

            var team = groups[j];
            var team2 = groups[j+1]


            var row = new TableRow();
            row.setStyle("height:24px;")

            var pos = new TableCol();
            pos.addControl("<b style='margin-inline-end: 5px;font-size:var(--text-size-small);'>"+team.position+"</b>");
            if (team.color) {
                pos.setStyle("border-top-right-radius: 8px;border-bottom-right-radius: 8px;background-color:" + team.color + "; color:" + Colorizer.getCorrectTextColor(team.color));
            }
            var image =new ImagePlaceHolder();
            image.placeholderData = teamPlaceholder
            image.addCssClass("tableImageSmall")
            image.setCss({'height':28+"px",'width':28+"px"});
            image.setImage(AppInstance.getConfig().url.imageBaseUrl+"/footapi/"+AppInstance.getConfig().url.teamPlayerImageUrl+"/teams_gs/"+team.id_gs+"_small_rnd.png",28,28);
            var imageCon = new TableCol();
            imageCon.addControl(image);
            var colArrow = new TableCol();
            if (team.position_change > 0){
                colArrow.addControl("<div class='arrow-up'></div>");
            }
            else if (team.position_change < 0){
                colArrow.addControl("<div class='arrow-down'></div>");
            }

            var col1 = new TableCol();

            if (team.id_gs == data.data.gs_localteamid){
                col1.addControl(data.data.localteam);
            }
            else{
                col1.addControl(data.data.visitorteam);
            }

            col1.setStyle("text-align: left;");
            var gp = new TableCol();
            gp.addControl(team.matchPoints+"");
            var w = new TableCol();
            w.addControl(team.totalWon+"");
            var d = new TableCol();
            d.addControl(team.totalDraw+"");
            var l = new TableCol();
            l.addControl(team.totalLost+"");
            var gf = new TableCol();
            gf.addControl(team.totalGoalsFor+" - "+team.totalGoalsAgainst);
            var gd = new TableCol();
            gd.addControl(team.totalGoalsFor - team.totalGoalsAgainst+"");
            var points = new TableCol();
            points.addControl(team.points+"");


            row.addControl(pos);
            row.addControl(colArrow);
            //row.addControl(imageCon);
            row.addControl(col1);
            row.addControl(gp);
            row.addControl(w);
            row.addControl(d);
            row.addControl(l);
            row.addControl(gf);
            row.addControl(gd);
            row.addControl(points);

            this.card.addControl(row);
        }

        this.card.addControl("</table>");

        this.card.bind('click',()=>{
            Router.showLeagueScreen(this.data.data,{"action":"showStandings"});
        });
    }
};

export class ShapeRender extends DataGridTileItemRender{

    init ()
    {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);
    }

    setData (data){
        super.setData(data);

        var title = new Panel();
        title.addControl(Localizer.getString("team_form"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var container = new Panel();
        container.addCssClass("MatchStatsContainerForm flexHorizontalAlign");

        var startContainer = new Panel();
        startContainer.addCssClass("MatchStatsContainerFormStart flexHorizontalAlign");

        var endContainer = new Panel();
        endContainer.addCssClass("MatchStatsContainerFormEnd flexHorizontalAlign");
        for (var index in data.data.localteamshape){
            var letter = data.data.localteamshape[index];
            var item = new Panel();
            if (letter == "W"){
                item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormWin");
            }
            else if (letter == "D"){
                item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormDraw");
            }
            else if (letter == "L"){
                item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormLost");
            }
            item.addControl(letter);
            startContainer.addControl(item);
        }

        for (var index in data.data.visitorteamshape){
            var letter = data.data.visitorteamshape[index];
            var item = new Panel();
            if (letter == "W"){
                item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormWin");
            }
            else if (letter == "D"){
                item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormDraw");
            }
            else if (letter == "L"){
                item.addCssClass("MatchStatsContainerFormItem MatchStatsContainerFormLost");
            }
            item.addControl(letter);
            endContainer.addControl(item);
        }

        container.addControl(startContainer);
        container.addControl(endContainer);

        this.card.addControl(container);
    }
};

export class CommentariesRender extends DataGridTileItemRender{

    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);

    }

    setData (data) {

        this.card.clear();
        var title = new Panel();
        title.addControl(Localizer.getString("liveticker"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var prevcomment = null;
        console.log("=======",data.data.commentaries);
        //reverse order
        data.data.commentaries.commentaries.reverse();
        for (var i=0; i < data.data.commentaries.commentaries.length;i++){
            var commentary = data.data.commentaries.commentaries[i];

            if (commentary.comment == prevcomment){
                continue;
            }
            var content = new Panel();
            content.addCssClass("flexHorizontalAlign MatchCommentary");
                var minute = new Panel();
                minute.setStyle("width:40px;text-align:center;margin-inline-end:6px;");
                minute.addControl(commentary.minute);

            if (commentary.minute){
                content.addControl(minute);
            }
            content.addControl("<div style='width:100%;margin-inline-end:10px;'>"+commentary.comment+"</div>");
            content.setStyle("margin-top: 3px; margin-bottom: 3px;");

            this.card.addControl(content);
            prevcomment = commentary.comment;
        }

        var showMore = new Panel();
        showMore.addCssClass("icon-showmore");
        //showMore.addControl(Localizer.getString("show_more"));
        showMore.addCssClass("showMoreButton");
        this.card.addControl(showMore);

        this.card.bind("click",()=>{
            var urlIdentifier = "matchmodal_"+UrlManager.getModule("mid");
            var modal = new CommentaryModal(urlIdentifier);
            this.getPage().addControl(modal,true);
        });
    }
};

export class OddsRender extends DataGridTileItemRender{

    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);

        this.addControl(this.panel);

    }

    setData (data) {

        this.card.clear();
        var title = new Panel();
        title.addControl(Localizer.getString("odd_title"));
        title.addCssClass("MatchStatsTitleText MatchH2HTitle")

        this.card.addControl(title);

        var topTitle = new Panel();
        topTitle.addCssClass("MatchStatsTitleText MatchH2HTitle")
        var topContainer = new Panel();
        topContainer.addCssClass("flexHorizontalAlign");
        topContainer.setStyle("justify-content: space-around;");

        var leftContainer = new Panel();
        var middleContainer = new Panel();
        var rightContainer = new Panel();

        leftContainer.addControl("1");
        middleContainer.addControl("X");
        rightContainer.addControl("2");

        topContainer.addControl(leftContainer);
        topContainer.addControl(middleContainer);
        topContainer.addControl(rightContainer);

        //this.card.addControl(topTitle);
        this.card.addControl(topContainer);

        console.log("ddd",data.data.odds);
        for (var i=0; i < data.data.odds.bookmakers.length;i++){
            var bookmaker = data.data.odds.bookmakers[i];
            this.renderOddItem(bookmaker)
            if (i==0) break;
        }

        var showMore = new Panel();
        showMore.addCssClass("icon-showmore");
        //showMore.addControl(Localizer.getString("show_more"));
        showMore.addCssClass("showMoreButton");
        this.card.addControl(showMore);

        this.card.bind("click",()=>{
            var urlIdentifier = "oddsmodal_"+UrlManager.getModule("mid");
            var modal = new OddsModal(urlIdentifier,data.data);
            this.getPage().addControl(modal,true);
        });

    }

    renderOddItem (bookmaker){
        var topTitle = new Panel();
        //topTitle.addControl("<i>"+bookmaker.name+"</i>");
        topTitle.addCssClass("MatchStatsTitleText MatchH2HTitle")
        var topContainer = new Panel();
        topContainer.addCssClass("flexHorizontalAlign");
        topContainer.setStyle("justify-content: space-around;margin-top:10px;");

        var leftContainer = new Panel();
        var middleContainer = new Panel();
        var rightContainer = new Panel();

        leftContainer.addControl(bookmaker.homeValue);
        middleContainer.addControl(bookmaker.drawValue);
        rightContainer.addControl(bookmaker.awayValue);

        topContainer.addControl(leftContainer);
        topContainer.addControl(middleContainer);
        topContainer.addControl(rightContainer);

        this.card.addControl(topTitle);
        this.card.addControl(topContainer);
    }

};

export class VideoHighlights extends DataGridTileItemRender{
    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        //this.panel.addControl(this.card);

        this.addControl(this.panel);
    }

    createComponents () {

        this.tabChangeListener = ()=>{
            this.pauseVideos();
        }
        if (this.getPage().tabview) {
            this.getPage().tabview.bind("tabChanged", this.tabChangeListener);
        }
    }

    unload (){
        for (var i=1; i < this.videoCount;i++){

            var videoEl = document.getElementById("video"+i);
            videoEl.removeEventListener('play', this.videoListeners[i]);
        }
        if (this.tabChangeListener && this.getPage().tabview) {
            this.getPage().tabview.unbind("tabChanged", this.tabChangeListener);
        }
        this.videoElements = null;
    }

    pauseVideos (){
        try{
            for (var i=1; i < this.videoCount;i++){
                var videoEl = document.getElementById("video"+i);
                videoEl.pause();
            }
        }catch(e){
            console.log(e);
        }
    }

    updateDisplay () {

        if (!this.videoListeners) this.videoListeners = [];
        if (!this.videoElements) this.videoElements = {};

        for (var i=1; i < this.videoCount;i++){

            var videoEl = document.getElementById("video"+i);
            this.videoElements["video"+i] = videoEl;

            videoEl.removeEventListener('play', this.videoListeners[i]);

            this.videoListeners[i] = (e) =>{

                for (var key in this.videoElements){
                    if (key != e.target.attributes.id.value){
                        this.videoElements[key].pause();
                    }
                }
            }
            videoEl.addEventListener('play', this.videoListeners[i] )
        }
    }

    setData (data) {

        this.panel.clear();

        var videoId = 1;

        var added = false;
        if (data.data.videoHighlights && data.data.videoHighlights.files.length){

            for (var i =0; i < data.data.videoHighlights.files.length;i++){
                this.videoElementAdded = true;

                //base64encode
                var encoded = "/footapi/images/videoreference/"+data.data.id+"_"+btoa(data.data.videoHighlights.files[i].url)+".jpg";

                var videoEl = `
            <video preload="none" poster=`+encoded+` id=video`+videoId+` width='100%' height='219' controls>
                 <source src='`+data.data.videoHighlights.files[i].url+`?rel=0#t=0.001' type='video/mp4'>
            </video>  
            `;
                this.panel.addControl(videoEl);
                //added = true;
                //break;
                videoId++;
            }
        }

        if (!added && data.data.videoHighlights && data.data.videoHighlights.clips.length){

            for (var i =0; i < data.data.videoHighlights.clips.length;i++){
                this.videoElementAdded = true;

                var encoded = "/footapi/images/videoreference/"+data.data.id+"_"+btoa(data.data.videoHighlights.clips[i].url)+".jpg";

                var videoEl = `

            <div style="text-align: center;padding:5px; font-variant: small-caps;">
            `+Localizer.getString(data.data.videoHighlights.clips[i].type)+` - `+data.data.videoHighlights.clips[i].score.replace("[","").replace("]","")+`
            </div>

            <video preload="none" poster=`+encoded+` id=video`+videoId+` width='100%' height='219' controls>
                 <source src='`+data.data.videoHighlights.clips[i].url+`?rel=0#t=0.001' type='video/mp4'>
            </video>  
            `;
                this.panel.addControl(videoEl);
                videoId++;
            }
        }

        this.videoCount = videoId;

        this.panel.invalidateDisplay();
    }

};

export class ShareMatchRender extends DataGridTileItemRender{
    init ()
    {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");

        var card = new Panel();
        card.addCssClass("MatchTitleContainerCard");
        card.addCssClass("flexVerticalAlign");
        this.panel.addControl(card);

        this.addControl(this.panel);

        var button = new Panel();
        button.addCssClass("shareButton");
        button.addControl(Localizer.getString("menu_sharematch"));
        card.addControl(button);

        this.panel.bind('click',()=>{
            Router.showLeagueScreen(this.data.data);
        });
    }
};

export class ExternalOddsIntegration extends DataGridTileItemRender{

    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-bottom:-5px;");
        this.panel.setVisible(false);

        this.card = new Panel();
        this.card.addCssClass("MatchStatsCotainerCard");
        //this.panel.addControl(this.card);

        this.addControl(this.panel);
    }

    listener (){

    }

    updateDisplay () {
        if (!this.videoElementAdded) return;

        if (this.fullscreenChangeListener){
            document.removeEventListener('fullscreenchange', this.fullscreenChangeListener);
        }
        this.fullscreenchangeListener = () =>{
            var isFullscreenNow = document.webkitFullscreenElement !== null
            this.listRender.triggerEvent('onFullscreenEvent', isFullscreenNow)
        }

        document.getElementById("video").addEventListener('fullscreenchange', this.fullscreenchangeListener )
    }

    setData (data) {

        var matchid = UrlManager.getModule("mid");

        DataProvider.getAdtekMatchOdds(matchid, (data) => {
            if (!data.isOk()){
                console.log("no ad avalable");
                return;
            }
            console.log("zzzee result ",data.getData());
            this.panel.setVisible(true);

            window.addEventListener('message', () =>{
                if (typeof event.data === 'object' && event.data.type === 'resize') {
                    const items = document.querySelectorAll('[id^="bwss-iframe"]');
                    items.forEach(item => {
                        item.style.height = event.data.data + 'px';
                    });
                }
            });
            this.panel.clear();
            this.panel.addControl(data.getData(),true);
        });
    }
};


import {Panel} from "../../bananaframework/src/controls/Panel.js";
import {UrlManager} from "../../bananaframework/src/util/UrlManager.js";

import {Modal} from "../modal.js";
import {Localizer} from "../../util/localizer.js";
import {DataProvider} from "../../data/dataprovider.js";

export class CommentaryModal extends Modal{

    init (urlIdentifier) {
        super.init(urlIdentifier);
    }

    unload (){
        clearTimeout(this.commentaryRefreshHandler);
    }

    createComponents () {

        super.createComponents();

        var dim = this.getPage().getDimensions();

        var p = new Panel();
        p.setStyle("height:" + (dim.height - 50) + "px;justify-content: flex-start;align-items: flex-start;overflow-y:scroll;");
        p.addCssClass("flexVerticalAlign");

        var title = new Panel();
        title.addCssClass("addFavoriteModalTitle");
        title.addControl(Localizer.getString("commentaries"));
        this.addControl(title);

        this.addControl(p)
        this.setVisible(false);

        setTimeout(()=>{
            this.setVisible(true);
            this.reValidateSize();
        },50);

        var matchid = UrlManager.getModule("mid");

        var loadCommentaries = ()=>{
            DataProvider.getCommentaries(matchid, (data)=> {
                if (!data.isOk()){
                    console.log("show error");
                    return;
                }
                var oldSCroll = jQuery("#"+p.getClientId()).scrollTop();
                p.clear();
                this.renderCommentaries(data.getData(),p);
                jQuery("#"+p.getClientId()).scrollTop(oldSCroll);

                clearTimeout(this.commentaryRefreshHandler);

                this.commentaryRefreshHandler = setTimeout(()=>{
                    loadCommentaries();
                },AppInstance.getConfig().refreshIntervals.commentary);
            });
        };

        loadCommentaries();
    }

    renderCommentaries (commentaries,target){
        var prevcomment = null;

        //reverse the commentaries
        commentaries.liveticker.reverse();

        for (var i=0; i < commentaries.liveticker.length;i++){
            var commentary = commentaries.liveticker[i];

            if (commentary.comment == prevcomment){
                continue;
            }
            var content = new Panel();
            content.addCssClass("flexHorizontalAlign");
            var minute = new Panel();
            minute.setStyle("width:60px;text-align:center;margin-inline-end:8px;");
            minute.addControl(commentary.minute);

            if (commentary.minute){
                content.addControl(minute);
            }
            content.addControl("<div style='width:100%;margin-inline-end:10px;'>"+commentary.comment+"</div>");
            content.setStyle("margin-top: 5px;text-align:left; margin-bottom: 5px;height:auto;");

            target.addControl(content);
            prevcomment = commentary.comment;
        }

        target.invalidateDisplay();
    }
};